<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="1200"
    min-width="800"
    z-index="100"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon title="История изменений">
        <m-icon icon="tool-chat" s16></m-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <base-list-view
          :dataSource="dataSource"
          :headers="headers"
          :loading="loading"
          @onRefresh="init"
          :customInplaceEdit="customInplaceEdit"
          @customDelete="customDelete"
          @afterEdit="UpdateEditable()"
          :editIndex.sync="editIndex"
          :defaultObject="defaultObject"
          show-actions
          :editable="(item) => !readonly && (item.canEdit || item.Id < 0)"
          notShowSelect
          :showDelete="false"
          :disabledAdd="readonly"
          title="История изменений документа"
          :icon="UtilGetIcon('Documents')"
          label-text-btn-create="Добавить комментарий"
          add-in-table
          hide-default-footer
          :showSettings="false"
        >
          <template #item.Comment="scope">
            <v-text-field
              v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
              :value="scope.item.Comment"
              @change="scope.rowEditing.Comment = $event"
              autofocus
              v-up-first-symbol
            ></v-text-field>
            <span v-else>{{ scope.item.Comment }}</span>
          </template>
        </base-list-view>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import LocalDocumentService from "../../../services/LocalDocumentService";
import { defaultLocalDocumentHistory } from "../../../data/defaultObjects";
import { OrganizationDocumentStatus } from "../../../data/enums";
import DataHelper from "../../../utils/DataHelper";
import BaseListView from "../../../layouts/BaseListView.vue";

export default {
  name: "view-DocumentHistory",
  components: { BaseListView },
  props: {
    documentId: Number,
    readonly: Boolean,
  },
  data: function () {
    return {
      menu: false,
      loading: true,
      dataLoaded: false,
      defaultObject: defaultLocalDocumentHistory,
      headers: [
        {
          text: "Дата",
          value: "Date",
          dataType: "Date",
          width: 170,
        },
        {
          text: "Состояние",
          value: "Status",
          dataType: "enum",
          options: OrganizationDocumentStatus,
        },
        { text: "Комментарий", value: "Comment" },
        {
          text: "Сотрудник",
          value: "Employee",
          dataType: "object",
          displayText: (e) => this.$options.filters.PersonShortName(e),
        },
      ],
      dataSource: [],
      editIndex: null,
    };
  },
  watch: {
    menu(value) {
      if (value && !this.dataLoaded) {
        this.dataLoaded = true;
        this.init();
      }
    },
  },
  methods: {
    UpdateEditable() {
      if (this.dataSource.length) {
        let last = this.dataSource[0];
        this.dataSource.forEach((item) => {
          item.canEdit = false;
          if (item.DateVal > last.DateVal) last = item;
        });
        if (last.Employee.Id === this.USER.Employee.Id) last.canEdit = true;
      }
    },
    async init() {
      try {
        this.loading = true;
        const res = await LocalDocumentService.getHistory(this.documentId);
        this.dataSource = res.data;
        this.dataSource.forEach((item) => {
          item.DateVal = DataHelper.toDateTime(item.Date).getTime();
        });
        this.UpdateEditable();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async customInplaceEdit(value) {
      if (!value) return;
      value.DocumentId = this.documentId;

      const res = (
        await LocalDocumentService[
          value.Id <= 0 ? "createHistory" : "updateHistory"
        ](value)
      ).data;
      res.DateVal = DataHelper.toDateTime(res.Date).getTime();
      return res;
    },
    async customDelete(value) {
      if (!value) return;
      if (value.Id > 0)
        await LocalDocumentService.deleteHistory(this.documentId);
      const index = this.dataSource.findIndex((item) => item.Id === value.Id);
      if (index >= 0) this.dataSource.splice(index, 1);
      if (value.Id > 0) this.UpdateEditable();
      this.editIndex = -1;
    },
    refresh() {
      this.dataLoaded = false;
    },
  },
};
</script>
